.page {
	width: 100vw;
	height: 100%;
	background: #f6f6f6;
}
.header {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100vw;
}
.nav-item {
	flex: 1 1 0;
	padding: 6px 0;
}
.searchView {
	position: relative;
	width: 80%;
	margin: 0 auto;
}
.searchIcon {
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translate(0, -50%);
}
.searchInput {
	width: 100%;
	height: 32px;
	padding-left: 16px;
	padding-right: 32px;
	line-height: 32px;
	border: none;
	border-radius: 32px;
	background: #fff;
}
.content {
	width: 100%;
	overflow-y: auto;
}
.contractUl {
	width: 100%;
	height: 100%;
}
.addView {
	position: absolute;
	right: 20px;
	bottom: 30px;
}
