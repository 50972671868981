.itemView {
	margin-top: 10px;
	padding: 6px 10px;
	background: #fff;
	border-radius: 6px;
	color: #333;
}
.signer {
	padding: 0 6px;
	font-size: 18px;
}
.bold {
	font-weight: 550;
}

.img{
	width: 65px;
	height: 65px;
}