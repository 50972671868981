.page {
	width: 100vw;
	height: 100%;
}
.required {
	color: red;
	font-size: 16px;
}
.authButton {
	margin-top: 20px;
}
.agreement{
	margin-top: 20px;
}