.sign {
    width: 100vw;

    .sign-container {
        .salary-sign-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100vh;
            .salary-sign-title {
                text-align: center;
                font-size: 18px;
            }

            .salary-sign-content {
                width: 100%;
                display: flex;
                justify-content: center;

                // padding: 0 10%;
                .sigCanvas {
                    // width: 90%;
                    // height: 80vh;
                }
            }

            .salary-sign-bar {
                display: flex;
                width: 100%;
                margin: 10px auto 0;

                .salary-sign-btn {
                    width: 50%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #000;
                    box-sizing: border-box;
                    font-size: 22px;

                    &:first-child {
                        border-right: 0;
                    }
                }
            }
        }
    }
}