.page {
	width: 100vw;
	height: 100%;
	background: #fff;
	.title{
		font-size: 18px;
		text-align: center;
		margin-top: 20px;
		font-weight: 600;
	}
	.info{
		padding: 0 15px;
	}
	.tit{
		color: #666;
		font-size: 16px;
	}
	.fs{
		color: #000;
		font-size: 14px;
	}
	.fw{
		color: #000;
		font-size: 14px;
		font-weight: 600;
		text-decoration: underline;
	}
}
