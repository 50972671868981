.page {
	width: 100vw;
	height: 100%;
}
.li {
	padding: 20px 0;
}
.li_label {
	min-width: 60px;
	flex-basis: 60px;
	text-align: left;
}
.queryView {
	margin-top: 20vh;
}
.tool {
	margin-top: 10px;
}
.tool_li {
	color: #40a9ff;
}
.inBox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.inLfe {
		display: flex;
		align-items: center;
		.name {
			width: 85px;
			text-align: left;
			font-size: 17px;
			font-size: #000;
			margin-left: 15px;
		}
		.in {
			height: 26px;
			width: 150px;
			font-size: 17px;
			font-size: #000;
			border: 0;
		}
	}

	.vls {
		font-size: 16px;
		color: #333333;
		background-color: #fff;
		border: 1px solid #e5e5e5;
		width: 100px;
		height: 30px;
		border-radius: 7px;
	}
}
.in {
	border-bottom: 0;
	.am-list-line {
		width: 50px !important;
	}
}
// .am-list-line::after{
// 	width: 0 !important;
// }
