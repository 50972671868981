*,
body {
    padding: 0;
    margin: 0;
}

body {
    width: 100vw;
}

.page {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.header {
    position: fixed;
    width: 100%;
    min-height: 60px;
    top: 0;
    z-index: 99;
}

.content {
    width: 100%;
    height: calc(100vh - 120px);
    overflow-y: scroll;
    position: fixed;
    top: 60px;
}

.DateSelect {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    color: #fff;
    z-index: 1;
}

.DateSelect input {
    color: #fff !important;
    text-align: right;
}

.banner {
    width: 100vw;
    object-fit: contain;
    z-index: 0;
}

.payrollSalary {
    font-size: 24px;
    font-weight: 500;
    color: #000;
}

.createTime {
    padding-left: 3px;
    font-size: 18px;
    color: #000;
}

.salaryContent {
    padding: 3px 0;
    background: #fff;
    overflow-y: auto;
}

.monthHeader {
    font-size: 16px;
    padding: 10px 20px;
    background: #f6f6f6;
}

.content_left {
    margin-left: 30px;
}

.arrow_right {
    padding: 10px;
}

.ls {
    margin-top: 20px;
}

.ls_top {
    display: flex;
    padding: 0 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.ls_time {
    font-size: 18px;
    color: #000;
}

.ls_img_box {
    width: 100vw;
    overflow-x: scroll;
}

.ls_img {
    // width: 100%;
    height: 100px;
}