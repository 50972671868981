.mask {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 1;
}
.maskBody {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 94vw;
	margin: 0 auto;
	z-index: 2;
}
.header {
	color: #fff;
	background: #002b44;
}
.title {
	display: block;
	padding: 10px 0px;
	font-size: 24px;
	color: #fff;
}
.safe {
	width: 12px;
	object-fit: contain;
}
.content {
	background: #fff;
}
.inputItem {
	width: 100%;
	height: 40px;
	padding: 0 20px;
	border: none;
	border-bottom: 1px solid #dcdfe6;
	border-radius: 2px;
}
.contentItem {
	width: 80%;
	margin: 0 auto;
	padding: 20px 0;
}
.phone {
	text-align: center;
	font-size: 20px;
	font-weight: 550;
}
.valiButton {
	color: #8cc5ff;
	background: #d9ecff;
}
