*,
body {
	padding: 0;
	margin: 0;
}
body {
	width: 100vw;
}
.page {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.header {
	position: relative;
	width: 100%;
	min-height: 60px;
}
.content {
	width: 100%;
	overflow-y: auto;
}
.DateSelect {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translate(0, -50%);
	color: #fff;
	z-index: 1;
}
.DateSelect input {
	color: #fff !important;
	text-align: right;
}
.banner {
	width: 100vw;
	object-fit: contain;
	z-index: 0;
}
.payrollSalary {
	font-size: 24px;
	font-weight: 500;
	color: #000;
}
.createTime {
	padding-left: 3px;
	font-size: 18px;
	color: #000;
}
.salaryContent {
	padding: 3px 0;
	background: #fff;
	overflow-y: auto;
}
.sallary_li:first-child .payrollSalary {
	color: #ff6600;
}
.sallary_li:first-child .createTime {
	color: #ff6600;
}
.sallary_li:first-child .salaryContent {
	background: #e9e9e9;
}
.monthHeader {
	font-size: 16px;
	padding: 10px 20px;
	background: #f6f6f6;
}
.content_left {
	margin-left: 30px;
}
.arrow_right {
	padding: 10px;
}
